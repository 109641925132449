import { graphql } from 'gatsby';
import React from 'react';
import AppHeader from '../../components/header.component';

export default function Component(props) {
  return <AppHeader>{props.data.eventsJson.title}</AppHeader>;
}

export const query = graphql`
  query EventsQuery($id: String!) {
    eventsJson(id: { eq: $id }) {
      title
      id
    }
  }
`;
